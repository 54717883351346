// Remove default primeng effect
button {
  outline: none;
  box-shadow: none;
}

//Base button properties
.btn {
  color: var(--gs1-color-white);
  border-radius: 4px;
  padding: 10px 16px;
  border: none;
  height: auto;
  min-width: 45px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;

  //Primary button properties
  &.btn-primary {
    &:enabled {
      background-color: var(--gs1-color-orange);
    }

    &:hover {
      background-color: var(--gs1-color-orange-accessible-dark-1);
      cursor: pointer;
    }

    &:active {
      background-color: var(--gs1-color-orange-accessible-dark-2);
    }

    &:focus {
      background-color: var(--gs1-color-orange);
      border: 2px solid var(--gs1-color-blue);
    }

    &:disabled {
      background-color: var(--gs1-color-ui-4);
      color: var(--gs1-color-ui-5);
      cursor: default;
    }
  }

  //Secondary button properties
  &.btn-secondary {
    &:enabled {
      background-color: var(--gs1-color-blue);
    }

    &:hover {
      background-color: var(--gs1-color-blue-dark-1);
      cursor: pointer;
    }

    &:active {
      background-color: var(--gs1-color-blue-dark-2);
    }

    &:focus {
      background-color: var(--gs1-color-blue);
      border: 2px solid var(--gs1-color-orange);
    }

    &:disabled {
      background-color: var(--gs1-color-ui-4);
      color: var(--gs1-color-ui-5);
      cursor: default;
    }
  }

  //Outlined button properties
  &.btn-tertiary {
    &:enabled {
      border: 1px solid var(--gs1-color-blue);
      background-color: transparent;
      color: var(--gs1-color-blue);
    }

    &:hover {
      border: 1px solid var(--gs1-color-blue-dark-1);
      background-color: var(--gs1-color-blue-dark-1);
      color: var(--gs1-color-white);
      cursor: pointer;
    }

    &:active {
      background-color: var(--gs1-color-blue-dark-2);
      color: var(--gs1-color-white);
    }

    &:focus {
      background-color: var(--gs1-color-blue);
      color: var(--gs1-color-white);
      border: 2px solid var(--gs1-color-orange);
    }

    &:disabled {
      border: none;
      background-color: var(--gs1-color-ui-4);
      color: var(--gs1-color-ui-5);
      cursor: default;
    }
  }

  //Blue link button properties
  &.btn-link-blue {
    &:enabled {
      background-color: transparent;
      color: var(--gs1-color-link);
      text-decoration: underline;
    }

    &:hover {
      background-color: transparent;
      color: var(--gs1-color-blue);
      cursor: pointer;
    }

    &:active {
      background-color: transparent;
      color: var(--gs1-color-link);
    }

    &:focus {
      background-color: transparent;
      color: var(--gs1-color-link);
      border: 2px solid var(--gs1-color-link);
    }
  }

  //White link button properties
  &.btn-link-white {
    &:enabled {
      background-color: transparent;
      color: var(--gs1-color-white);
      text-decoration: underline;
    }

    &:hover {
      background-color: transparent;
      color: var(--gs1-color-white);
      cursor: pointer;
    }

    &:active {
      background-color: transparent;
      color: var(--gs1-color-white);
    }

    &:focus {
      background-color: transparent;
      color: var(--gs1-color-white);
      border: var(--gs1-color-white);
    }
  }

  //Upload button properties
  &.btn-upload {
    &:enabled {
      background-color: transparent;
      color: var(--gs1-color-link);
    }

    &:hover {
      background-color: transparent;
      color: var(--gs1-color-blue);
      cursor: pointer;
    }

    &:active {
      background-color: transparent;
      color: var(--gs1-color-link);
    }

    &:focus {
      background-color: transparent;
      color: var(--gs1-color-link);
      border: 2px solid var(--gs1-color-link);
    }

    &:disabled {
      border: none;
      background-color: transparent;
      color: var(--gs1-color-ui-5);
      cursor: default;
    }
  }

  //Black link button properties
  &.btn-link-black {
    &:enabled {
      padding: 0;
      background-color: transparent;
      color: var(--gs1-color-ui-8);
      text-decoration: underline;
    }

    // &:hover {
    //   background-color: transparent;
    //   color: var(--gs1-color-blue);
    //   cursor: pointer;
    // }

    // &:active {
    //   background-color: transparent;
    //   color: var(--gs1-color-link);
    // }

    // &:focus {
    //   background-color: transparent;
    //   color: var(--gs1-color-link);
    //   border: 2px solid var(--gs1-color-link);
    // }
    // &:disabled {
    //   border: none;
    //   background-color: transparent;
    //   color: var(--gs1-color-ui-5);
    //   cursor: default;
    // }
  }

  //Grey link button properties
  &.btn-link-grey {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
    padding: 5px 14px;

    &:enabled {
      background-color: transparent;
      color: var(--gs1-color-ui-6);
    }

    // &:hover {
    //   background-color: transparent;
    //   color: var(--gs1-color-blue);
    //   cursor: pointer;
    // }

    // &:active {
    //   background-color: transparent;
    //   color: var(--gs1-color-link);
    // }

    // &:focus {
    //   background-color: transparent;
    //   color: var(--gs1-color-link);
    //   border: 2px solid var(--gs1-color-link);
    // }
    // &:disabled {
    //   border: none;
    //   background-color: transparent;
    //   color: var(--gs1-color-ui-5);
    //   cursor: default;
    // }
  }
}

// Button arrow properties
.button-arrow::after {
  content: "";
  width: 1rem;
  height: 1rem;
  margin-left: 8px;
  background: no-repeat center/contain;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-arrow-right-short" viewBox="0 -1.15 16 16"><path fill-rule="evenodd" d="M10.293 1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 8H1a1 1 0 1 1 0-2h11.586L10.293 3.121a1 1 0 0 1 0-1.414z"/></svg>');
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

/* Change arrow color to --gs1-color-blue when .btn.btn-tertiary is in enabled state to match the text */
.btn.btn-tertiary.button-arrow:enabled::after {
  content: "";
  width: 1rem;
  height: 1rem;
  margin-left: 8px;
  background: no-repeat center/contain var(--gs1-color-blue);

  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-arrow-right-short" viewBox="0 -1.15 16 16"><path fill-rule="evenodd" d="M10.293 1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 8H1a1 1 0 1 1 0-2h11.586L10.293 3.121a1 1 0 0 1 0-1.414z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-arrow-right-short" viewBox="0 -1.15 16 16"><path fill-rule="evenodd" d="M10.293 1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 8H1a1 1 0 1 1 0-2h11.586L10.293 3.121a1 1 0 0 1 0-1.414z"/></svg>');

  transition-duration: 0.2s;
  transition-timing-function: ease;
}

/* Change arrow color to white when .btn.btn-tertiary is in hover, active or focus state to match the text */
.btn.btn-tertiary.button-arrow:hover::after,
.btn.btn-tertiary.button-arrow:active::after,
.btn.btn-tertiary.button-arrow:focus::after {
  content: "";
  width: 1rem;
  height: 1rem;
  margin-left: 8px;
  background: no-repeat center/contain;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-arrow-right-short" viewBox="0 -1.15 16 16"><path fill-rule="evenodd" d="M10.293 1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 8H1a1 1 0 1 1 0-2h11.586L10.293 3.121a1 1 0 0 1 0-1.414z"/></svg>');
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.button-arrow:hover::after {
  transform: translateX(4px);
}

.button-arrow:disabled::after {
  transform: none;
  background: no-repeat center/contain var(--gs1-color-ui-5);

  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-arrow-right-short" viewBox="0 -1.15 16 16"><path fill-rule="evenodd" d="M10.293 1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 8H1a1 1 0 1 1 0-2h11.586L10.293 3.121a1 1 0 0 1 0-1.414z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-arrow-right-short" viewBox="0 -1.15 16 16"><path fill-rule="evenodd" d="M10.293 1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 8H1a1 1 0 1 1 0-2h11.586L10.293 3.121a1 1 0 0 1 0-1.414z"/></svg>');
}

.btn.btn-tertiary.button-arrow:disabled::after {
  transform: none;
  background: no-repeat center/contain var(--gs1-color-ui-5);

  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-arrow-right-short" viewBox="0 -1.15 16 16"><path fill-rule="evenodd" d="M10.293 1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 8H1a1 1 0 1 1 0-2h11.586L10.293 3.121a1 1 0 0 1 0-1.414z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-arrow-right-short" viewBox="0 -1.15 16 16"><path fill-rule="evenodd" d="M10.293 1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 8H1a1 1 0 1 1 0-2h11.586L10.293 3.121a1 1 0 0 1 0-1.414z"/></svg>');
}
