:root {
    --e-global-color-primary: #002c6c;
    --e-global-color-secondary: #54595f;
    --e-global-color-text: #262626;
    --e-global-color-accent: #f26334;
    --e-global-color-1bcf640: #002c6c;
    --e-global-color-090801b: #f26334;
    --e-global-color-be110d5: #f05587;
    --e-global-color-e53220a: #bd80b7;
    --e-global-color-eaa3076: #af96d4;
    --e-global-color-a4683d5: #89aadb;
    --e-global-color-0102de9: #00b6de;
    --e-global-color-51e17a2: #008dbd;
    --e-global-color-1fad6b7: #8db9ca;
    --e-global-color-babab00: #22bcb9;
    --e-global-color-59d4523: #71b790;
    --e-global-color-bb1b32b: #7ac143;
    --e-global-color-51f0a96: #00ac4a;
    --e-global-color-04954fd: #9dbb68;
    --e-global-color-66b33e6: #c1d82f;
    --e-global-color-65b3d80: #c4b000;
    --e-global-color-20080a4: #fbb034;
    --e-global-color-b311e3e: #ff8200;
    --e-global-color-a15a606: #b78b20;
    --e-global-color-510d348: #d18158;
    --e-global-color-c40e02d: #f0f5fa;
    --e-global-color-b4cedd0: #e5f0fc;
    --e-global-color-d9c5c95: #c4d7ed;
    --e-global-color-eb6dc70: #e5e8eb;
    --e-global-color-4b43e8f: #a6b0bb;
    --e-global-color-9e21267: #696d73;
    --e-global-color-bede60d: #414345;
    --e-global-color-9b0e797: #262626;
    --e-global-color-41d23cd: #ba1b23;
    --e-global-color-3d60d76: #cd3c0d;
    --e-global-color-f581610: #d12b61;
    --e-global-color-35d0d5f: #a2539a;
    --e-global-color-cdcffe1: #835cbc;
    --e-global-color-cf49326: #3a70bf;
    --e-global-color-bccb657: #007b93;
    --e-global-color-395fb09: #00799e;
    --e-global-color-b5d8dc6: #41778c;
    --e-global-color-ed7242c: #177c7a;
    --e-global-color-361eaad: #3f7c5a;
    --e-global-color-28f049e: #417e12;
    --e-global-color-695360e: #008037;
    --e-global-color-a9c595d: #677642;
    --e-global-color-befe60f: #667800;
    --e-global-color-44d9bca: #826f00;
    --e-global-color-6f35f72: #9d6304;
    --e-global-color-be54910: #a15700;
    --e-global-color-0a4f658: #8d6b19;
    --e-global-color-f8cd129: #ab5a2f;
    --e-global-typography-primary-font-family: "GothamSSm";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "GothamSSm";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "GothamSSm";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "GothamSSm";
    --e-global-typography-accent-font-weight: 500;
    color: var(--e-global-color-text)
}

ul.jet-menu.jet-menu--animation-type-move-down.jet-menu--roll-up {
    min-width: 1000px !important;
}